// .modal is already in use by the template
.modal-container {
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 50px;
    visibility: visible;
    display: inline;

    .modal-content {
        background-color: #fff;
        border-radius: 5px;
        margin: 0 auto;
        filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px);
    }
}

.example-appear {
    opacity: 0.01;
}

.example-appear.example-appear-active {
    opacity: 1;
    transition: opacity 400ms ease-out;
}

.modal-footer-center,
.modal-footer.centered {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
}
