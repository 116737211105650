@mixin make-theme-classes($selectorPrefix, $property, $theme, $value, $percent) {
    $number: $percent * 100;
    .#{$selectorPrefix}-#{$theme}-#{$number}-lighten {
        #{$property}: lighten($value, $number) !important;
    }
    .#{$selectorPrefix}-#{$theme}-#{$number}-lighten-hover:hover {
        #{$property}: lighten($value, $number) !important;
    }
    .#{$selectorPrefix}-#{$theme}-#{$number}-percent-transparent {
        #{$property}: scale-color($color: $value, $alpha: percentage(-$percent)) !important;
    }
}

@each $theme, $value in map-merge($theme-colors, $colors) {
    .border-#{$theme} {
        border-color: $value;
    }
    .fill-#{$theme} {
        fill: $value;
    }
    .stroke-#{$theme} {
        stroke: $value;
    }
    .text-#{$theme} {
        color: $value;
    }
    $percent: 0.05;
    @while $percent < 1 {
        $number: $percent * 100;
        @include make-theme-classes(text, color, $theme, $value, $percent);
        @include make-theme-classes(bg, background-color, $theme, $value, $percent);
        @include make-theme-classes(border, border-color, $theme, $value, $percent);
        .opacity-#{$number} {
            opacity: $percent;
        }
        $percent: $percent + 0.05;
    }
}

.stroke-grey {
    stroke: #e0e0e0;
}
