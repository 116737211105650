//
// type.scss
//

// Font sizes (Custom)
.font-13 {
    font-size: 13px !important;
}

.font-14 {
    font-size: 14px !important;
}

.font-15 {
    font-size: 15px !important;
}

.font-16 {
    font-size: 16px !important;
}

.font-18 {
    font-size: 18px !important;
}
