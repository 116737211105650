//
// authentication.scss
//

// authentication pages background
body.authentication-bg {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 800'%3E%3Cg fill-opacity='0.22'%3E%3Ccircle fill='%23ffffff' cx='400' cy='400' r='600'/%3E%3Ccircle fill='%23eff3ff' cx='400' cy='400' r='500'/%3E%3Ccircle fill='%23cddbff' cx='400' cy='400' r='300'/%3E%3Ccircle fill='%23bbcfff' cx='400' cy='400' r='200'/%3E%3Ccircle fill='%23a8c4ff' cx='400' cy='400' r='100'/%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
    background-position: center;
}

// Logout page
.logout-icon {
    width: 140px;
}
