//
// modal.scss
//

.modal-header {
    padding: 0.5rem;
    .close {
        position: absolute;
        right: 1rem;
        top: 1rem;
    }
}
.modal-dialog-scrollable .modal-content form {
    max-height: calc(90vh - 1rem);
    display: flex;
    flex-direction: column;
}

@media (min-width: 576px) {
    .modal-dialog-scrollable .modal-content form {
        max-height: calc(90vh - 3.5rem);
    }
}
