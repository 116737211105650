/*
Template Name: Hyper - Responsive Bootstrap 4 Admin Dashboard
Version: 1.1.0
Author: CoderThemes
Email: support@coderthemes.com
File: Main Css File
*/

//Core files
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import 'config/variables';
@import 'config/custom-variables';
@import './node_modules/bootstrap/scss/bootstrap';

@import 'modal.scss';

//Fonts
@import 'custom/fonts/nunito';

//Components
@import 'custom/components/mixins';
@import 'custom/components/accordions';
@import 'custom/components/avatar';
@import 'custom/components/breadcrumb';
@import 'custom/components/buttons';
@import 'custom/components/card';
@import 'custom/components/dropdown';
@import 'custom/components/modal';
@import 'custom/components/nav';
@import 'custom/components/pagination';
@import 'custom/components/popover';
@import 'custom/components/print';
@import 'custom/components/progress';
@import 'custom/components/reboot';
@import 'custom/components/switch';
@import 'custom/components/tables';
@import 'custom/components/type';
@import 'custom/components/utilities';
@import 'custom/components/widgets';

//Pages
@import 'custom/pages/authentication';
@import 'custom/pages/error';
@import 'custom/pages/faq';
@import 'custom/pages/grid-demo';
@import 'custom/pages/icons-demo';
@import 'custom/pages/maintenance';
@import 'custom/pages/tasks';

// Plugins
@import 'custom/plugins/britechart';
@import 'custom/plugins/calendar';
@import 'custom/plugins/chartjs';
@import 'custom/plugins/datatable';
@import 'custom/plugins/daterange';
@import 'custom/plugins/dragula';
@import 'custom/plugins/dropzone';
@import 'custom/plugins/maps';
@import 'custom/plugins/metis-menu';
@import 'custom/plugins/select2';
@import 'custom/plugins/slimscroll';
@import 'custom/plugins/toaster';

html,
body {
    height: 100%;
    overflow: hidden;
}

a:hover {
    cursor: pointer;
}

.flex {
    display: flex;
}

.align-items-center {
    align-items: center;
    a {
        vertical-align: baseline;
    }
}

.flex-wrap {
    flex-wrap: wrap;
}

.justify-content-space-between {
    justify-content: space-between;
}

.striped {
    background: repeating-linear-gradient(
        -45deg,
        #fafbfe,
        #fafbfe 10px,
        #ffffff 10px,
        #ffffff 20px
    );
}

.page-title,
.report-header {
    display: flex;
    align-items: center;
}

.report-header {
    h6 {
        margin: 0px;
    }
}

.report-title {
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 1.1;
}

$toast-success-background: #cef5ea;
$toast-success-color: #056c4f;
.toast-success {
    background: $toast-success-background !important;
    .toast-success-body {
        background: $toast-success-background;
        color: $toast-success-color;
    }
    .toast-success-progress {
        background: #baf2e2;
    }
    .Toastify__close-button {
        color: $toast-success-color;
    }
}

.toast-error {
    background: $red !important;
    .toast-error-body {
        background: $red;
        color: white;
    }
    .Toastify__close-button {
        color: white;
    }
}

input[type='file'] {
    padding: 5px;
}

.unselectable {
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Likely future */
}

/* Google Maps Autocomplete Drop-Down Container */
.pac-container {
    min-width: 50%;
    // puts the google maps autocomplete above the bootstrap modal 1250 zindex.
    z-index: 1300 !important;
}

.custom-control-input:disabled ~ .custom-control-label {
    color: #98a6ad;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: #e0e0e0;
}

.disabled-link {
    pointer-events: none;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@keyframes color {
    100%,
    0% {
        stroke: #5cc2a7;
    }
    40% {
        stroke: #ffcc66;
    }
    66% {
        stroke: #ff679b;
    }
    80%,
    90% {
        stroke: #6b5eae;
    }
}

.callout-box {
    overflow: hidden;

    .corner-icon {
        position: absolute;
        left: 0;
        bottom: -30px;
        font-size: 3.7rem;
        opacity: 0.4;
        transform: rotate(30deg);
    }
}

.report-comparison {
    margin: 0;
    padding: 0;
    width: 100%;

    thead {
        td {
            font-weight: bold;
        }
    }
}

.warning-text {
    font-weight: bold;
    color: $red;
}

.warning-icon {
    color: $red;
    font-size: 32px;
}

.overflow-unset {
    overflow: unset !important;
    * {
        overflow: unset !important;
    }
}

.clickable {
    cursor: pointer;
}

.pg-viewer-wrapper {
    overflow-y: unset !important;
}

#rh-file-preview-modal {
    .document-container {
        @extend .card;
    }

    .pdf-canvas {
        @extend .text-center;
        canvas {
            @extend .card;
            @extend .m-auto;
        }
    }
}

.BeaconContainer,
.BeaconFabButtonFrame {
    z-index: 9999 !important; // over modals
}

/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
}
