.ReactTable {
    position: relative;
    border-top: 0px solid rgba(0, 0, 0, 0.1);
}
.ReactTable * {
    box-sizing: border-box;
}
.ReactTable .rt-table {
    display: flex;
    justify-content: stretch;
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto;
}

.ReactTable .rt-table .rt-inner {
    flex: 1 0 auto;
}

.ReactTable .rt-thead {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 700;
}
.ReactTable .rt-thead.-headerGroups {
    background: rgba(0, 0, 0, 0.03);
    border-bottom: 0px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-thead.-filters {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none;
}
.ReactTable .rt-thead.-filters .rt-th {
    border-right: 1px solid rgba(0, 0, 0, 0.02);
}
.ReactTable .rt-thead.-header {
    box-shadow: 0 17px 15px -15px rgba(0, 0, 0, 0.1);
}
.ReactTable .rt-thead .rt-tr {
    flex-wrap: nowrap;
    text-align: center;
    gap: 10px;
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
    padding: 20px 0px;
    line-height: normal;
    position: relative;
    border-right: 0px solid rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: inset 0 0 0 0 transparent;
    overflow: hidden;
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
}
.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
    cursor: pointer;
}
.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
    border-right: 0;
}
.ReactTable .rt-thead .rt-resizable-header {
    display: flex;
    justify-content: space-between;
    min-width: 0 !important;
}

.ReactTable .rt-thead .rt-resizable-header-content {
    text-overflow: ellipsis;
}
.ReactTable .rt-thead .rt-header-pivot {
    border-right-color: #f7f7f7;
}
.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.ReactTable .rt-thead .rt-header-pivot:after {
    border-color: rgba(255, 255, 255, 0);
    border-left-color: #fff;
    border-width: 8px;
    margin-top: -8px;
}
.ReactTable .rt-thead .rt-header-pivot:before {
    border-color: rgba(102, 102, 102, 0);
    border-left-color: #f7f7f7;
    border-width: 10px;
    margin-top: -10px;
}
.ReactTable .rt-tbody {
    position: relative;
}
.ReactTable .rt-tbody .rt-tr {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
    gap: 10px;
}
.ReactTable .rt-tbody .rt-tr:last-child {
    border-bottom: 0;
}
.ReactTable .rt-tbody .rt-td {
    border-right: 0px solid rgba(0, 0, 0, 0.02);
    overflow: hidden;
}
.ReactTable .rt-tbody .rt-td:last-child {
    border-right: 0;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
    display: flex;
    align-items: center;
    white-space: unset;
    text-overflow: ellipsis;
    padding: 15px 0px;
    transition: 0.3s ease;
    transition-property: width, min-width, padding, opacity;
    min-width: 0px;
}
.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
    width: 0 !important;
    min-width: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    opacity: 0 !important;
}
.ReactTable .rt-expander {
    display: inline-block;
    position: relative;
    margin: 0;
    color: transparent;
    margin: 0 10px;
}

.ReactTable .rt-resizer {
    display: inline-block;
    position: absolute;
    right: 0;
    width: 18px;
    height: 100%;
    cursor: col-resize;
    z-index: 10;
}
.ReactTable .rt-tfoot {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}
.ReactTable.-striped .rt-tr.-odd {
    background: rgba(0, 0, 0, 0.03);
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-noData {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    z-index: 1;
    pointer-events: none;
    padding: 14px;
    color: rgba(0, 0, 0, 0.5);
}
.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
    transition: none !important;
    cursor: col-resize;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.Table__itemCount {
    font-size: 14px;
}
.Table__pagination {
    display: flex;
    justify-content: space-between;
}
.Table__pageButton {
    color: #6c757d;
    margin: 0 8px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.Table__pageButton:disabled {
    cursor: not-allowed;
    color: #cccccc;
}
.Table__pageButton--active {
    color: #ffffff;
    background-color: #66cc99;
    border-radius: 30px !important;
}

.overflow-visible {
    overflow: visible !important;
}
