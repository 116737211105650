// Variables

//
// Color system
//

// stylelint-disable
$gray-100: #f1f3fa;
$gray-200: #e3eaef;
$gray-600: #98a6ad;
$gray-700: #6c757d;
$gray-900: #313a46;

$grays: ();
$grays: map-merge(
    (
        '100': $gray-100,
        '200': $gray-200,
        '300': $gray-300,
        '400': $gray-400,
        '500': $gray-500,
        '600': $gray-600,
        '700': $gray-700,
        '800': $gray-800,
        '900': $gray-900,
    ),
    $grays
);

$blue: #2c8ef8;
$cyan: #39afd1;
$gray: #cccccc;
$green: #0acf2b;
$indigo: #727cf5;
$invalid-red: #dc3545;
$oldnavy: #374958;
$orange: #cc6666;
$pink: #ff679b;
$purple: #6b5eae;
$red: #fa5c7c;
$sage: #99cc99;
$skyblue: #63adf2;
$steel: #666666;
$teal: #5cc2a7;
$yellow: #ffcc66;

$colors: ();
$colors: map-merge(
    (
        'blue': $blue,
        'indigo': $indigo,
        'purple': $purple,
        'pink': $pink,
        'red': $red,
        'invalid-red': $invalid-red,
        'orange': $orange,
        'yellow': $yellow,
        'green': $green,
        'teal': $teal,
        'cyan': $cyan,
        'white': $white,
        'gray': $gray-600,
        'gray-dark': $gray-800,
    ),
    $colors
);

$primary: $teal;
$secondary: $oldnavy;
$success: $green;
$info: $skyblue;
$warning: $yellow;
$danger: $red;
$light: $gray-200;
$dark: $gray-900;

$theme-colors: ();
$theme-colors: map-merge(
    (
        'default': $gray-700,
        'primary': $primary,
        'secondary': $secondary,
        'success': $success,
        'info': $info,
        'warning': $warning,
        'danger': $danger,
        'light': $light,
        'dark': $dark,
    ),
    $theme-colors
);

// Customize the light and dark text colors for use in our YIQ color contrast function.

$yiq-text-dark: $gray-900;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1.5rem;
$spacers: ();
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.5,
        ),
        5: (
            $spacer * 2,
        ),
        6: (
            $spacer * 2.5,
        ),
        7: (
            $spacer * 3,
        ),
    ),
    $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
$sizes: map-merge(
    (
        15: 15%,
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
    ),
    $sizes
);

// Body
//
// Settings for the `<body>` element.

$body-bg: #fafbfe;
$body-color: $gray-700;

// Links
//
// Style anchor elements.

$link-color: $primary;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;

// Components
//
$component-active-bg: $primary;

$caret-width: 0.25em;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Nunito', sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Times', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;

// stylelint-enable value-keyword-case
$font-size-base: 0.875rem;
$font-weight-bold: 700;

$headings-margin-bottom: $spacer;
$headings-font-weight: 400;
$headings-line-height: 1.1;

$h1-font-size: 2.25rem;
$h2-font-size: 1.875rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.125rem;
$h5-font-size: 0.9375rem;
$h6-font-size: 0.75rem;

$display-line-height: $headings-line-height;

$lead-font-size: ($font-size-base * 1.25);

$small-font-size: 0.75rem;

$text-muted: $gray-600;

$blockquote-font-size: ($font-size-base * 1.25);

$hr-border-color: $gray-200;

$list-inline-padding: 6px;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.95rem;
$table-cell-padding-sm: 0.5rem;

$table-accent-bg: $gray-100;
$table-hover-bg: $gray-100;

$table-border-color: $gray-200;

$table-head-bg: $gray-100;
$table-head-color: $gray-700;

$table-dark-bg: $gray-900;
$table-dark-border-color: lighten($gray-900, 7.5%);
$table-dark-color: $gray-600;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.97rem;
$input-btn-padding-x: 0.9rem;

$input-btn-focus-width: 0.15rem;
$input-btn-focus-color: rgba($primary, 0.25);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: 0.28rem;
$input-btn-padding-x-sm: 0.8rem;

$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-line-height: $input-btn-line-height;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-line-height-sm: $input-btn-line-height-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-line-height-lg: $input-btn-line-height-lg;

$btn-font-weight: $font-weight-normal;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;

$btn-link-disabled-color: $gray-500;

// Allows for customizing button radius independently from global border radius

$btn-border-radius: 0.15rem;
$btn-border-radius-lg: 0.15rem;
$btn-border-radius-sm: 0.15rem;

// Forms

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-line-height-sm: $input-btn-line-height-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-line-height-lg: $input-btn-line-height-lg;

$input-color: $body-color;
$input-border-width: 1px;
$input-border-color: $gray-300;
$input-border-radius: 0.25rem;

$input-focus-box-shadow: none;
$input-focus-border-color: darken($input-border-color, 10%);

$input-placeholder-color: $gray-500;

$input-height-border: $input-border-width * 2;

$input-height-inner: ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2);
$input-height: calc(#{$input-height-inner} + #{$input-height-border});

$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2);
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border});

$input-height-inner-lg: ($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2);
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border});

$input-group-addon-border-color: $input-border-color;

$custom-select-padding-y: 0.45rem;
$custom-select-padding-x: 0.9rem;
$custom-select-height: $input-height;

$custom-control-indicator-checked-color: $primary;
$custom-control-indicator-checked-bg: $primary;

$custom-select-border-width: $input-border-width;
$custom-select-border-color: $input-border-color;
$custom-select-border-radius: $border-radius;
$custom-select-focus-border-color: $input-focus-border-color;
$custom-select-focus-box-shadow: none;
$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg, $input-btn-focus-box-shadow;

$custom-file-height: $input-height;
$custom-file-height-inner: $input-height-inner;
$custom-file-focus-border-color: $input-focus-border-color;
$custom-file-focus-box-shadow: none;

$custom-file-padding-y: $input-btn-padding-y;
$custom-file-padding-x: $input-btn-padding-x;
$custom-file-line-height: $input-btn-line-height;
$custom-file-color: $input-color;
$custom-file-bg: $input-bg;
$custom-file-border-width: $input-border-width;
$custom-file-border-color: $input-border-color;
$custom-file-border-radius: $input-border-radius;
$custom-file-box-shadow: none;
$custom-file-button-color: $custom-file-color;
$custom-file-button-bg: $input-group-addon-bg;
$custom-file-text: (
    en: 'Browse',
) !default;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-padding-y: 0.25rem;
$dropdown-border-color: darken($light, 3%);

$dropdown-link-color: $body-color;
$dropdown-link-hover-color: $headings-color;

$dropdown-link-active-color: $dark;
$dropdown-link-active-bg: lighten($light, 3%);

$dropdown-item-padding-y: 0.375rem;

$dropdown-header-color: inherit;

// Navs
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

// Pagination

$pagination-color: $dark;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow;

$pagination-hover-color: $dark;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;

// Cards

$card-spacer-x: $spacer;
$card-border-color: $gray-200;
$card-cap-bg: $white;

// Tooltips

$tooltip-border-radius: 0.2rem;
$tooltip-padding-y: 0.4rem;
$tooltip-padding-x: 0.8rem;

// Popovers

$popover-border-color: $gray-200;

$popover-header-bg: $gray-100;
$popover-header-padding-y: 0.7rem;
$popover-header-padding-x: 0.8rem;

// Badges

$badge-font-weight: $font-weight-bold;

// Modals

$modal-content-border-color: transparent;
$modal-content-border-radius: 0.2rem;

$modal-backdrop-bg: $gray-900;
$modal-backdrop-opacity: 0.7;

// Progress bars

$progress-bar-bg: theme-color('primary');

// List group

$list-group-active-bg: $component-active-bg;

// Breadcrumbs

$breadcrumb-padding-y: $spacer/1.5;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: 0.5rem;

$breadcrumb-bg: transparent;
$breadcrumb-divider: quote('\F142');
$breadcrumb-divider-color: $gray-400;
$breadcrumb-active-color: $gray-500;

// Close

$close-text-shadow: none;

// Code

$code-color: $cyan;

/*
  z-index overrides
  Bootstrap default z-index values: https://getbootstrap.com/docs/4.4/layout/overview/#z-index
  Material UI default z-index values: https://mui.com/customization/z-index/#main-content
  These overrides put Bootstrap's modal and the rest above the Material UI drawer
 */

$zindex-modal-backdrop: 1240;
$zindex-modal: 1250;
$zindex-popover: 1260;
$zindex-tooltip: 1270;
