//
// tabs.scss
//

.nav-tabs,
.nav-pills {
    > li {
        > a {
            color: $gray-700;
            font-weight: $font-weight-semibold;
        }
    }
}

.nav-pills {
    > a {
        color: $gray-700;
        font-weight: $font-weight-semibold;
    }
}

//
// nav-bordered
//

.nav-tabs.nav-bordered {
    border-bottom: 0px solid rgba($gray-600, 0.2);

    .nav-item {
        margin-bottom: 0px;
    }

    li {
        a {
            border: 0;
            padding: 0.825rem 1.25rem 0.825rem 1.25rem;
        }
        a.active {
            border-top: 0px solid $primary;
            box-shadow: 0px -2px 2px 1px rgba(154, 161, 171, 0.07);
        }
    }
}

.nav-tabs2.nav-bordered2 {
    border-bottom: 2px solid rgba($gray-600, 0.2);

    .nav-item {
        margin-bottom: -2px;
    }

    li {
        a {
            color: $gray-900;
            border: 0;
            padding: 0.625rem 1.25rem;
        }
        a.active {
            border-bottom: 2px solid $primary;
        }
    }
}
