.launch-details-table {
    tbody {
        td {
            padding-top: 10px;
        }
    }
}

.ld-value {
    padding-left: 25px;
}
