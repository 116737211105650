//
// grid-demo.scss
//

.grid-structure {
    .grid-container {
        background-color: $gray-100;
        margin-bottom: 10px;
        font-size: 0.8rem;
        font-weight: $font-weight-semibold;
        padding: 10px 20px;
    }
}
