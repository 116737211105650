//
// custom-variables.scss
//

// Shadow
$shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.08);
$shadow-sm: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
$shadow-lg: 0 0 45px 0 rgba(0, 0, 0, 0.06);

//Background left-sidebar
$bg-leftbar: linear-gradient(135deg, $oldnavy 0%, $steel 250%);
$bg-leftbar-light: #f1f3fa;
$bg-topnav: linear-gradient(to bottom, #000000, #000000);

// Leftbar width
$leftbar-width: 200px;

//Menu item colors (Default-dark)
$menu-item: #dbdbdb;
$menu-item-hover: #ffffff;
$menu-item-active: #ffffff;

//Menu item colors (light)
$menu-item-light: #6c757d;
$menu-item-light-hover: #8f75da;
$menu-item-light-active: #8f75da;

//Rightbar Width
$rightbar-width: 260px;

//Topbar Background
$bg-topbar: #ffffff;

// Font weight
$font-weight-semibold: 600;

// Dropdown Large
$dropdown-lg-width: 320px;
