//
// maps.scss
//

//Google Maps

.gmaps,
.gmaps-panaroma {
    height: 300px;
    background: $light;
    border-radius: 3px;
}

// Vector Map

.jvectormap-label {
    border: none;
    background: $dark;
    color: $white;
    font-family: $font-family-base;
    font-size: $font-size-base;
    padding: 5px 8px;
}
